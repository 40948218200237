import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { SET_CURRENT_USER, ADD_PREFERENCES } from "./types";
import { BASE_URL } from "../../utils/url";
import Cookies from "universal-cookie";
const prod = process.env.NODE_ENV === "production";

const cookies = new Cookies();

export const signupUser = (userData) => () => {
  const url = BASE_URL + "auth/signup";

  return axios
    .post(url, userData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const addPreferencesToCart = (userData) => {
  return {
    type: ADD_PREFERENCES,
    payload: userData,
  };
};

export const confirmationEmail = (data) => {
  const url = BASE_URL + "auth/confirmation";

  return axios
    .post(url, data)
    .then((res) => {
      console.log(res);
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const loginUser = (userData) => (dispatch) => {
  const url = BASE_URL + "auth/login";

  return axios.post(url, userData).then((res) => {
    const { token } = res.data;

    if (prod) {
      cookies.set("dy_tkn_au", token, { path: "/", domain: ".datayoshi.com" });
    } else {
      cookies.set("dy_tkn_au", token, { path: "/" });
    }
    // Set token to auth header
    setAuthToken(token);
    // Decode token to get user data (user name, user id, avatar)
    const decoded = jwt_decode(token);

    // Set current user
    dispatch(setCurrentUser(decoded));

    return res.data;
  });
};

export const recoverPasswordEmail = (userData) => {
  const url = BASE_URL + "auth/recover";

  return axios
    .post(url, userData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const checkTokenResetPassword = (id) => {
  const url = BASE_URL + "auth/reset/" + id;

  return axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const resetPassword = (id, userData) => {
  const url = BASE_URL + "auth/reset/" + id;

  return axios
    .post(url, userData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const setCurrentUser = (decoded) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_USER,
    payload: decoded,
  });
};

// Log user out

export const logoutUser = (history) => (dispatch) => {
  const url = BASE_URL + "auth/logout";
  console.log(url);
  axios.post(url).then(() => history.push("/login"));
  // Remove auth header for future requests and cookie
  setAuthToken(false);
  if (prod) {
    cookies.remove("dy_tkn_au", { path: "/", domain: ".datayoshi.com" });
  } else {
    cookies.remove("dy_tkn_au", { path: "/" });
  }
  dispatch(setCurrentUser({}));
};
