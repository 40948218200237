import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LANDING_URL } from "../utils/url";
import { connect } from "react-redux";
import { logoutUser, setCurrentUser } from "../redux/actions/authActions";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

const Header = ({ ...props }) => {
  const showCross = () => {
    setCross(!cross);
  };

  const history = useHistory();
  const dispatch = useDispatch();

  const [cross, setCross] = useState(false);

  const { auth } = props;

  const isAuthenticated = auth.isAuthenticated;
  const userType = auth.user.userType;

  const SignOutUser = () => {
    dispatch(logoutUser(history));
    setCross(false);
  };

  const handleCross = () => {
    setCross(!cross);
  };

  return (
    <div
      data-collapse="medium"
      data-animation="default"
      data-duration="400"
      data-w-id="58db7844-5919-d71b-dd74-2323ed8dffe9"
      role="banner"
      className="header w-nav"
      style={{ opacity: "1" }}
    >
      <div className="container-default container-header w-container">
        <a href={LANDING_URL} className="brand w-nav-brand" aria-label="home">
          <img
            src="https://res.cloudinary.com/boina/image/upload/v1590329068/DataYoshi/public/profile.png"
            alt=""
            className="header-logo logo-website"
          />
        </a>
        <div role="navigation" className="nav-menu-container w-nav-menu">
          <div className="spacer header-mobile"></div>
          <div className="nav-menu flex-center">
            <ul role="list" className="header-navigation">
              <li className="nav-item-wrapper">
                <a href={LANDING_URL} className="nav-link">
                  Home
                </a>
              </li>
              <li className="nav-item-wrapper">
                <a href={LANDING_URL + "jobs"} className="nav-link">
                  Jobs
                </a>
              </li>
              <li className="nav-item-wrapper">
                <a href={LANDING_URL + "blog"} className="nav-link">
                  Blog
                </a>
              </li>{" "}
              {isAuthenticated && (
                <>
                  <li className="nav-item-wrapper">
                    <Link to="/profile/me">
                      <a aria-current="page" className="nav-link">
                        My profile
                      </a>
                    </Link>
                  </li>
                  <li className="nav-item-wrapper">
                    <Link to="/coding-challenges">
                      <a aria-current="page" className="nav-link">
                        Coding challenges
                      </a>
                    </Link>
                  </li>
                  <li className="nav-item-wrapper">
                    <Link to="/newsletter">
                      <a
                        aria-current="page"
                        className="button-primary button-header-primary w-button"
                      >
                        My plan
                      </a>
                    </Link>
                  </li>
                  {/* 
                  <li className='nav-item-wrapper'>
                    <Link to='/plans'>
                      <a
                        aria-current='page'
                        className='button-primary button-header-primary w-button'
                      >
                        Pro
                      </a>
                    </Link>
                  </li>
                  
                  */}
                </>
              )}
            </ul>
          </div>
          <div className="_2-buttons-header">
            {!isAuthenticated ? (
              <>
                <Link to="/signup">
                  <a
                    aria-current="page"
                    className="button-secondary button-header-secondary w-button w--current margin-right-1"
                  >
                    Sign up
                  </a>
                </Link>
                <Link to="/login">
                  <a
                    aria-current="page"
                    className="button-secondary button-header-secondary w-button w--current margin-right-1"
                  >
                    Log in
                  </a>
                </Link>
                <Link to="/hire">
                  <a
                    aria-current="page"
                    className="button-primary button-header-primary w-button"
                  >
                    Post a job
                  </a>
                </Link>
              </>
            ) : (
              <div onClick={SignOutUser}>
                <a
                  aria-current="page"
                  className="button-primary button-header-primary w-button"
                >
                  Log out
                </a>
              </div>
            )}
          </div>
        </div>
        {!cross && (
          <div
            onClick={showCross}
            data-w-id="58db7844-5919-d71b-dd74-2323ed8dfffb"
            className="menu-button w-nav-button"
            style={{ WebkitUserSelect: "text" }}
            aria-label="menu"
            role="button"
            tabindex="0"
            aria-controls="w-nav-overlay-0"
            aria-haspopup="menu"
            aria-expanded="false"
          >
            <div
              data-w-id="9a64e014-3850-efda-23a3-56fba6ba1020"
              data-animation-type="lottie"
              data-src="https://assets.website-files.com/5ec5d86528da2f24250d634c/5ec5f8bbe2d02e5ece239ae7_lottieflow-menu-nav-07-05152E-easey.json"
              data-loop="0"
              data-direction="1"
              data-autoplay="0"
              data-is-ix2-target="1"
              data-renderer="svg"
              data-default-duration="2.4791666666666665"
              data-duration="0"
              data-ix2-initial-state="0"
              className="menu-lottie-icon"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 730 630"
                width="730"
                height="630"
                preserveAspectRatio="xMidYMid meet"
                style={{
                  width: "100%",
                  height: "100%",
                  transform: "translate3d(0px, 0px, 0px)",
                }}
              >
                <defs>
                  <clipPath id="__lottie_element_2">
                    <rect width="730" height="630" x="0" y="0"></rect>
                  </clipPath>
                </defs>
                <g clip-path="url(#__lottie_element_2)">
                  <g
                    transform="matrix(1,0,0,1,365,315)"
                    opacity="1"
                    style={{ display: "block" }}
                  >
                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        fill-opacity="0"
                        stroke="rgb(5,20,45)"
                        stroke-opacity="1"
                        stroke-width="55"
                        d=" M-327,-176.5 C-327,-176.5 327,-176.5 327,-176.5"
                      ></path>
                    </g>
                  </g>
                  <g
                    transform="matrix(1,0,0,1,365,315)"
                    opacity="1"
                    style={{ display: "block" }}
                  >
                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        fill-opacity="0"
                        stroke="rgb(5,20,45)"
                        stroke-opacity="1"
                        stroke-width="55"
                        d=" M-327,176.5 C-327,176.5 327,176.5 327,176.5"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        )}
        {cross && (
          <div
            onClick={showCross}
            data-w-id="58db7844-5919-d71b-dd74-2323ed8dfffb"
            className="menu-button w-nav-button w--open"
            style={{ WebkitUserSelect: "text" }}
            aria-label="menu"
            role="button"
            tabIndex={0}
            aria-controls="w-nav-overlay-0"
            aria-haspopup="menu"
            aria-expanded="true"
          >
            <div
              data-w-id="9a64e014-3850-efda-23a3-56fba6ba1020"
              data-animation-type="lottie"
              data-src="https://assets.website-files.com/5ec5d86528da2f24250d634c/5ec5f8bbe2d02e5ece239ae7_lottieflow-menu-nav-07-05152E-easey.json"
              data-loop={0}
              data-direction={1}
              data-autoplay={0}
              data-is-ix2-target={1}
              data-renderer="svg"
              data-default-duration="2.4791666666666665"
              data-duration={0}
              data-ix2-initial-state={0}
              className="menu-lottie-icon"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 730 630"
                width={730}
                height={630}
                preserveAspectRatio="xMidYMid meet"
                style={{
                  width: "100%",
                  height: "100%",
                  transform: "translate3d(0px, 0px, 0px)",
                }}
              >
                <defs>
                  <clipPath id="__lottie_element_2">
                    <rect width={730} height={630} x={0} y={0} />
                  </clipPath>
                </defs>
                <g clipPath="url(#__lottie_element_2)">
                  <g
                    transform="matrix(-0.7071067690849304,-0.7071067690849304,0.7071067690849304,-0.7071067690849304,489.8043518066406,189.69564819335938)"
                    opacity={1}
                    style={{ display: "none" }}
                  >
                    <g opacity={1} transform="matrix(1,0,0,1,0,0)">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fillOpacity={0}
                        stroke="rgb(5,20,45)"
                        strokeOpacity={1}
                        strokeWidth={55}
                        d=" M-327,-176.5 C-327,-176.5 327,-176.5 327,-176.5"
                      />
                    </g>
                  </g>
                  <g
                    transform="matrix(-0.7071067690849304,0.7071067690849304,-0.7071067690849304,-0.7071067690849304,489.8043518066406,438.3043518066406)"
                    opacity={1}
                    style={{ display: "none" }}
                  >
                    <g opacity={1} transform="matrix(1,0,0,1,0,0)">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fillOpacity={0}
                        stroke="rgb(5,20,45)"
                        strokeOpacity={1}
                        strokeWidth={55}
                        d=" M-327,176.5 C-327,176.5 327,176.5 327,176.5"
                      />
                    </g>
                  </g>
                  <g
                    transform="matrix(-0.7071067690849304,-0.7071067690849304,0.7071067690849304,-0.7071067690849304,489.8043518066406,189.69564819335938)"
                    opacity={1}
                    style={{ display: "block" }}
                  >
                    <g opacity={1} transform="matrix(1,0,0,1,0,0)">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fillOpacity={0}
                        stroke="rgb(5,20,45)"
                        strokeOpacity={1}
                        strokeWidth={55}
                        d=" M-327,-176.5 C-327,-176.5 327,-176.5 327,-176.5"
                      />
                    </g>
                  </g>
                  <g
                    transform="matrix(-0.7071067690849304,0.7071067690849304,-0.7071067690849304,-0.7071067690849304,489.8043518066406,438.3043518066406)"
                    opacity={1}
                    style={{ display: "block" }}
                  >
                    <g opacity={1} transform="matrix(1,0,0,1,0,0)">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fillOpacity={0}
                        stroke="rgb(5,20,45)"
                        strokeOpacity={1}
                        strokeWidth={55}
                        d=" M-327,176.5 C-327,176.5 327,176.5 327,176.5"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        )}
      </div>
      {!cross && (
        <div
          className="w-nav-overlay"
          data-wf-ignore=""
          id="w-nav-overlay-0"
        ></div>
      )}

      {cross && (
        <div
          className="w-nav-overlay"
          data-wf-ignore
          id="w-nav-overlay-0"
          style={{ height: "13715.8px", display: "block" }}
        >
          <div
            role="navigation"
            className="nav-menu-container w-nav-menu"
            style={{
              transform: "translateY(0px) translateX(0px)",
              transition: "transform 400ms ease 0s",
            }}
            data-nav-menu-open
            onClick={handleCross}
          >
            <div className="spacer header-mobile" />
            <div className="nav-menu">
              <ul role="list" className="header-navigation">
                <li className="nav-item-wrapper">
                  <a
                    href={LANDING_URL}
                    aria-current="page"
                    className="nav-link w--current"
                  >
                    Home
                  </a>
                </li>
                <li className="nav-item-wrapper">
                  <a
                    href={LANDING_URL + "jobs"}
                    aria-current="page"
                    className="nav-link w--current"
                  >
                    Jobs
                  </a>
                </li>
                <li className="nav-item-wrapper">
                  <a
                    href={LANDING_URL + "blog"}
                    aria-current="page"
                    className="nav-link w--current"
                  >
                    Blog
                  </a>
                </li>
                {isAuthenticated && (
                  <>
                    <li className="nav-item-wrapper">
                      <Link to="/profile/me">
                        <a aria-current="page" className="nav-link w--current">
                          My profile
                        </a>
                      </Link>
                    </li>
                    <li className="nav-item-wrapper">
                      <Link to="/newsletter">
                        <a
                          aria-current="page"
                          className="button-primary button-header-primary w-button"
                        >
                          My plan
                        </a>
                      </Link>
                    </li>
                    {/*  <li className='nav-item-wrapper'>
                      <Link to='/plans'>
                        <a
                          aria-current='page'
                          className='button-primary button-header-primary w-button'
                        >
                          Pro
                        </a>
                      </Link>
                    </li> */}
                  </>
                )}
              </ul>
            </div>
            <div className="_2-buttons-header">
              {!isAuthenticated ? (
                <>
                  <Link to="/signup">
                    <a
                      aria-current="page"
                      className="button-secondary button-header-secondary w-button margin-bottom-1"
                    >
                      Sign up
                    </a>
                  </Link>
                  <Link to="/login">
                    <a
                      aria-current="page"
                      className="button-secondary button-header-secondary w-button"
                    >
                      Log in
                    </a>
                  </Link>
                  <Link to="/hire">
                    <a
                      aria-current="page"
                      className="button-primary button-header-primary w-button"
                    >
                      Post a job
                    </a>
                  </Link>
                </>
              ) : (
                <div onClick={SignOutUser}>
                  <a
                    aria-current="page"
                    className="button-primary button-header-primary w-button"
                  >
                    Log out
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.authentication,
});

export default connect(mapStateToProps, {
  logoutUser,
  setCurrentUser,
})(Header);
