import React, { useState, useRef } from "react";
import { updateProfileUser, deletePhoto } from "../../utils/api";
import { BASE_URL } from "../../utils/url";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const UploadPhoto = ({
  setDialogUploadToFalse,
  fileUploaded,
  handleErrorSize,
  handleLoadingUpload,
  removeErrorSize,
  setSuccessUpload,
  photo,
}) => {
  const [open, setOpen] = useState(true);

  const urlUpload = BASE_URL + "profile/photo/upload";

  const handleClose = () => {
    setOpen(false);
    setDialogUploadToFalse();
  };

  const handleUpload = (fileUploaded) => {
    let file = fileUploaded;

    // Split the filename to get the name and type

    let fileName = file.name.replace(/\.[^/.]+$/, "");
    let fileType = file.name.split(".").pop();
    let folderName = "Datayoshi/profilePictures";

    console.log(file);
    console.log(typeof file);
    console.log("Preparing the upload");
    axios
      .post(urlUpload, {
        fileName: fileName,
        fileType: fileType,
        fileSize: file.size,
        folderName: folderName,
      })
      .then((response) => {
        handleLoadingUpload(true);
        if (response.data.success) {
          if (photo) {
            const photoData = {
              fileName: photo.fileName,
              folderName: photo.folderName,
            };

            console.log(photoData);

            deletePhoto(photoData)
              .then((res) => {
                if (res.success) {
                  console.log("deleted old file");
                }
              })
              .catch((err) => console.log(err));
          }

          const returnData = response.data.data.returnData;
          const signedRequest = returnData.signedRequest;
          const url = returnData.url;
          const folderName = returnData.folderName;
          const fileNameAWS = returnData.fileName;

          // delete JWT token
          delete axios.defaults.headers.common["Authorization"];

          // Put the fileType in the headers for the upload
          const options = {
            headers: {
              "Content-Type": fileType,
            },
          };

          axios
            .put(signedRequest, file, options)
            .then((result) => {
              axios.defaults.headers.common["Authorization"] = cookies.get(
                "dy_tkn_au"
              );

              const userData = {
                url,
                folderName,
                fileName: fileNameAWS,
                photo: true,
              };

              updateProfileUser(userData)
                .then((res) => {
                  if (res.success) {
                    setSuccessUpload();
                    removeErrorSize();
                  }
                })
                .catch((err) => {
                  console.log("Could not update photo");
                });

              handleClose();
            })
            .catch((error) => {
              console.log("ERROR " + JSON.stringify(error));
              axios.defaults.headers.common["Authorization"] = cookies.get(
                "dy_tkn_au"
              );
            });
        } else {
          handleClose();
          handleErrorSize(response.data.message);
          handleLoadingUpload(false);
        }
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Profile picture upload"}
        </DialogTitle>
        <DialogContent>
          {photo ? (
            <DialogContentText id="alert-dialog-description">
              Uploading a profile picture will replace your existing picture.
              Are you sure you want to continue?
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-description">
              We will now upload your profile picture. Are you sure you want to
              continue?
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>

          {photo ? (
            <Button
              onClick={() => handleUpload(fileUploaded)}
              color="primary"
              autoFocus
            >
              Update profile picture
            </Button>
          ) : (
            <Button
              onClick={() => handleUpload(fileUploaded)}
              color="primary"
              autoFocus
            >
              Add profile picture
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UploadPhoto;
