import React, { useEffect, useState } from 'react'
import isEmpty from '../../validation/is-empty'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ChoosePlan from './choosePlan'

import {
  getMyNewsletter,
  getCurrentPrices,
  getUserPaidStatus
} from '../../utils/api'

function getRandomInt (max) {
  return Math.floor(Math.random() * max)
}

const Start = ({ ...props }) => {
  const history = useHistory()
  const [hasNewsletter, setHasNewsletter] = useState(true)
  const [hasNewsletterSet, setHasNewsletterSet] = useState(true)
  const [hasProfile, setHasProfile] = useState(true)
  const [startingPrice, setStartingPrice] = useState(null)
  const [trialDays, setTrialDays] = useState(0)

  useEffect(() => {
    const { auth } = props
    const isAuthenticated = auth.isAuthenticated

    if (isAuthenticated) {
      getMyNewsletter().then(res => {
        if (isEmpty(res)) {
          setHasNewsletter(false)
          getCurrentPrices().then(res => {
            const initialPrice =
              res.prices.filter(
                x => x.proposition === 'bundle' && x.plan === 'monthly'
              )[0].details[0].unit_amount / 100
            setStartingPrice(initialPrice)

            setTrialDays(res.trialDays)
          })
          /*
          const value = getRandomInt(3)
          if (value === 0) {
            setHasNewsletter(false)
            getCurrentPrices().then(res => {
              const initialPrice =
                res.prices.filter(
                  x => x.proposition === 'bundle' && x.plan === 'monthly'
                )[0].details[0].unit_amount / 100
              setStartingPrice(initialPrice)

              setTrialDays(res.trialDays)
            })
          } else if (value === 1) {
            history.push('/payment/candidate/jobs')
          } else {
            history.push('/payment/candidate/bundle')
            
          }*/
          history.push('/newsletter')
        } else {
          getUserPaidStatus().then(res => {
            if (res.success && !isEmpty(res.data)) {
              const data = res.data[0]
              const formula = data.formula
              if (formula === 'premium') {
                history.push('/profile/me')
              } else {
                history.push('/newsletter')
              }
            } else {
              history.push('/newsletter')
            }
          })
        }
      })
    } else {
      history.push('/login')
    }
  }, [])

  return (
    <div>
      {/* 
      
       {!hasNewsletter && startingPrice && (
        <div>
          <ChoosePlan startingPrice={startingPrice} trialDays={trialDays} />
        </div>
      )}
      
      */}
    </div>
  )
}

const mapStateToProps = state => ({
  auth: state.authentication
})

export default connect(mapStateToProps, {})(Start)
