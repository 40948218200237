import React, { useEffect } from "react";

import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import {
  redirectToOffer,
  redirectToOfferVisitor,
  getUserPaidStatus,
} from "../../utils/api";

const RedirectToOffer = ({ ...props }) => {
  let { slugNumber, slugUrl } = useParams();

  const history = useHistory();

  useEffect(() => {
    const { auth } = props;
    const isAuthenticated = auth.isAuthenticated;

    const data = {
      slugNumber,
      slugUrl,
    };

    if (isAuthenticated) {
      getUserPaidStatus().then((res) => {
        if (res.canApplyToJobs) {
          redirectToOffer(data).then((res) => {
            if (res.success) {
              const link = res.data;
              if (link.includes("@")) {
                window.location.href = `mailto:${link}`; // For email links
              } else {
                window.location.href = link; // For regular URLs
              }
            }
          });
        } else {
          history.push("/newsletter");
        }
      });
      /*
      redirectToOffer(data).then(res => {
        if (res.success) {
          const link = res.data
          window.location.href = link
        } else {
          history.push('/signup')
        }
      })
      */
    } else {
      redirectToOfferVisitor(data).then((res) => {
        console.log(res);
        if (res.success) {
          const link = res.data;
          window.location.href = link.includes("@") ? `mailto:${link}` : link;
        } else {
          history.push("/signup");
        }
      });
    }
  }, []);

  return <div className="container margin-bottom-45"></div>;
};

const mapStateToProps = (state) => ({
  auth: state.authentication,
});

export default connect(mapStateToProps, {})(RedirectToOffer);
