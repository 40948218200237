import React, { useState, useEffect } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useParams, useHistory } from "react-router-dom";
import { getChallenge } from "../../utils/api";
import { connect } from "react-redux";

const CodeChallenge = ({ ...props }) => {
  const { slug } = useParams();
  const history = useHistory();
  const [challenge, setChallenge] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const { auth } = props;

    const isAuthenticated = auth.isAuthenticated;

    if (isAuthenticated) {
      getChallenge(slug).then((res) => {
        if (res.status === "success") {
          setChallenge(res.data);
          setLoading(false);
        } else {
          setLoading(false);
          setError(res.message);
        }
      });
    } else {
      history.push("/login");
    }
  }, [slug]); // Dependency on slug to ensure re-fetching if the slug changes

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!challenge) return <div>No challenge data found.</div>;

  const goBack = () => {
    history.push("/coding-challenges");
  };

  const navigateToNewsletter = () => {
    history.push("/newsletter");
  };

  const isContentBlurred = challenge.userType === "free";

  return (
    <div className="challenge-container">
      <button onClick={goBack} className="go-back-button">
        Go Back
      </button>
      <h1>{challenge.problemName}</h1>
      <h2>Problem Statement</h2>
      <p>{challenge.problemQuestion}</p>
      <h2>Description</h2>
      <p>{challenge.problemDescription}</p>
      <h2>Solution</h2>
      {isContentBlurred ? (
        <div className="blur-overlay">
          <div className="blurred-content">
            <p>{challenge.solutionPlainText}</p>
            <SyntaxHighlighter language="python" style={vscDarkPlus}>
              {challenge.solutionCode}
            </SyntaxHighlighter>
          </div>
          <button className="subscribe-button" onClick={navigateToNewsletter}>
            Subscribe to access the solution
          </button>
        </div>
      ) : (
        <div>
          <p>{challenge.solutionPlainText}</p>
          <SyntaxHighlighter language="python" style={vscDarkPlus}>
            {challenge.solutionCode}
          </SyntaxHighlighter>
        </div>
      )}
      <h2>Dataset</h2>
      <a href={challenge.dataSource} target="_blank" rel="noopener noreferrer">
        Download Dataset
      </a>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.authentication,
});

export default connect(mapStateToProps, {})(CodeChallenge);
