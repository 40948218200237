const skills = [
  "Python",
  "Spark",
  "SQL",
  "Java",
  "AWS",
  "Linux",
  "Machine Learning",
  "TensorFlow",
  "Tableau",
  "Matlab",
  "Data Mining",
  "Data Visualization",
  "ETL",
  "Modeling",
  "NLP",
  "Hadoop",
  "NumPy",
  "SciPy",
  "Cassandra",
  "Kafka",
  "Scala",
  "Pandas",
  "A/B Testing",
  "SPSS",
  "NoSQL",
  "Deep Learning",
  "Reinforcement Learning",
  "Bayesian",
  "Business Intelligence",
  "Looker",
  "Keras",
  "scikit-learn",
  "Power BI",
  "QlikView",
  "R",
  "BigQuery",
  "XGBoost",
  "Jupyter Notebook",
  "Dataproc",
  "AutoML",
  "Conda",
  "PyTorch",
  "Alteryx",
];
export default skills;
