import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { loginUser } from "../../redux/actions/authActions";
import { signUp } from "../../utils/api";
import { useHistory } from "react-router-dom";
import { GOOGLE_URL, LANDING_URL } from "../../utils/url";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const SignUp = ({ loginUser, ...props }) => {
  const history = useHistory();

  const [accountCreationStarted, setAccountCreationStarted] = useState(false);
  const [error, setError] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const onSubmit = (data) => {
    setAccountCreationStarted(true);

    signUp(data).then((res) => {
      if (res.success) {
        loginUser(data)
          .then(() => {
            setAccountCreationStarted(false);
            history.push("/start");
          })
          .catch((err) => {
            setAccountCreationStarted(false);
            setError(err.response.data.message);
          });
      } else {
        setAccountCreationStarted(false);
        setError(res.message);
      }
    });
  };

  useEffect(() => {
    const { auth } = props;

    const isAuthenticated = auth.isAuthenticated;
    const user = auth.user;

    if (isAuthenticated) {
      history.push("/start");
    }
  }, []);

  return (
    <>
      <div className="utility-page-wrap">
        <div
          data-w-id="5e86ada79942c1e4247fd4c700000000000b"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="utility-page-content-password w-password-page w-form"
        >
          <div className="container-default">
            <div className="">
              <img
                src="https://assets.website-files.com/5ec5d86528da2f24250d634c/5ecda3ef4028e0abe32b01b8_icon-password-jobs-template.svg"
                alt=""
                className="icon-password"
              />
            </div>
            <h2>Sign up</h2>
            <p>Create an account now to apply and receive our newsletter</p>

            <div className="flex-center">
              <a href={GOOGLE_URL + "auth/google"}>
                <span className="margin-left-1 google-button w-button w--current google-button-box margin-bottom-2">
                  <div className="google-icon-wrapper flex justify-center">
                    <img
                      className="google-icon margin-right-1"
                      src="/google.png"
                      style={{ width: "2rem", height: "2rem" }}
                    />
                  </div>
                  Sign up with Google
                </span>
              </a>
            </div>

            {error && (
              <h4 className="error__wrong text-align-center">😥 {error}</h4>
            )}
            <hr />
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="utility-page-form w-password-page"
          >
            <input
              type="email"
              autofocus="true"
              placeholder="Enter your email"
              {...register("email", {
                required: "Please enter your e-mail address",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Enter a valid e-mail address",
                },
              })}
              className="input password w-password-page w-input"
            />
            {errors.email && (
              <h3 className="error__invalid">{errors.email.message}</h3>
            )}
            <input
              type="password"
              autofocus="true"
              {...register("password", {
                required: "Please enter your password",
                minLength: {
                  value: 6,
                  message: "Minimum is length is 6",
                },
              })}
              placeholder="Enter your password"
              className="input password w-password-page w-input"
            />
            {errors.password && (
              <h3 className="error__invalid">{errors.password.message}</h3>
            )}
            {accountCreationStarted ? (
              <div className="flex-center margin-top-1">
                <div className="lds-default">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            ) : (
              <button
                type="submit"
                className="button-primary full-width w-password-page w-button"
              >
                Sign up
              </button>
            )}
          </form>{" "}
          <p className="margin-top-2">
            By creating an account you agree to our{" "}
            <a href={LANDING_URL + "privacy"}>Terms & Privacy</a>
          </p>
          <Link to="/login" variant="body2">
            <div className="margin-top-2">Already have an account? Sign in</div>
          </Link>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  auth: state.authentication,
});

export default connect(mapStateToProps, { loginUser })(SignUp);
