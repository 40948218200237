//https://stackoverflow.com/questions/62454487/stripe-v3-setupintents-and-subscriptions
// 4000002500003155 card for SCA
import React, { useState } from "react";
import PrintObject from "./PrintObject";
import { Button } from "@material-ui/core";
import {
  faCcAmex,
  faCcVisa,
  faCcMastercard,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LockIcon from "@material-ui/icons/Lock";
import {
  createCustomer,
  createSubscription,
  createLifeTimeNewsletter,
  verifyPayment,
  createSetupIntents,
  verifyEmail,
} from "../../utils/api";
import { connect } from "react-redux";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useHistory } from "react-router-dom";
import { loginUser } from "../../redux/actions/authActions";

const prod = process.env.NODE_ENV === "production";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#6772e5",
      color: "#6772e5",
      fontWeight: "500",
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "#6772e5",
      },
    },
    invalid: {
      iconColor: "#ef2961",
      color: "#ef2961",
    },
  },
};

const ElementsForm = ({ isUpgrade, loginUser, ...props }) => {
  const [input, setInput] = useState({
    cardholderName: "",
  });
  const [payment, setPayment] = useState({ status: "initial" });
  const [paymentStarted, setPaymentStarted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [cardCompleted, setCardCompleted] = useState(false);

  const [success, setSuccess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const { selectedPlan, email, newsletterData, trialDays } = props;

  let currentPrice;
  currentPrice =
    selectedPlan &&
    selectedPlan.details &&
    selectedPlan.details[0].unit_amount / 100;

  const PaymentStatus = ({ status }) => {
    switch (status) {
      case "processing":
      case "requires_payment_method":
      case "requires_confirmation":
        return <h2>Processing...</h2>;

      case "requires_action":
        return <h2>Authenticating...</h2>;

      case "succeeded":
        return <h2>Payment Succeeded 🥳</h2>;

      case "error":
        return (
          <>
            <h2>Error 😭</h2>
            <p className="error-message">{errorMessage}</p>
          </>
        );

      default:
        return null;
    }
  };
  const createPaymentMethod = (card, customerId, priceId) => {
    const response = createSetupIntents({ customer: customerId }).then(
      (data) => {
        if (data.client_secret) {
          const clientSecret = data.client_secret;
          return stripe
            .confirmCardSetup(clientSecret, {
              payment_method: {
                card: card,
                billing_details: {
                  email: email,
                },
              },
            })
            .then((result) => {
              if (result.error) {
                setPayment({ status: "error" });
                setErrorMessage(result.error.message);
                setPaymentStarted(false);
                return "error";
              } else {
                return createSubscription({
                  customerId: customerId,
                  paymentMethodId: result.setupIntent.payment_method,
                  priceId: priceId,
                  selectedPlan: selectedPlan,
                  newsletterData: newsletterData,
                }).then((res) => {
                  return res;
                });
              }
            });
        } else {
          setPayment({ status: "error" });

          setPaymentStarted(false);
          return "error";
        }
      }
    );

    return response;
  };

  const createPlan = async () => {
    const priceId = selectedPlan.details[0].id;

    const planType = selectedPlan.details[0].type;
    setPaymentStarted(true);
    if (planType === "recurring") {
      const customerId = createCustomer({ email: email });

      customerId.then((res) => {
        if (res.success) {
          const customerId = res.customerId;
          const card = elements.getElement(CardElement);
          const subscription = createPaymentMethod(card, customerId, priceId);

          subscription.then((response) => {
            if (response.success) {
              setPaymentStarted(false);
              setSuccess(true);
              setSuccessMessage(response.message);
              history.push("/start");
            }
          });
        } else {
          setErrorMessage(res.message);
          setPaymentStarted(false);
        }
      });
    } else {
      const data = {
        priceId: priceId,
      };

      const response = await verifyPayment(data);

      const clientSecret = response.clientSecret;

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);

      // Use your card Element with other Stripe.js APIs
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: { email: email },
          },
        }
      );

      if (error) {
        setPayment({ status: "error" });
        setErrorMessage(error.message);
        setPaymentStarted(false);
      } else if (paymentIntent) {
        const paymentIntentId = paymentIntent.id;

        const data = {
          paymentIntentId,
          selectedPlan,
          newsletterData,
        };
        createLifeTimeNewsletter(data).then((res) => {
          if (res.success) {
            setPaymentStarted(false);
            setSuccess(true);
            setSuccessMessage(res.message);
            history.push("/start");
          } else {
            //setErrorMessage("Oops something went wrong!");
            setErrorMessage(res.message);
            setPaymentStarted(false);
          }
        });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createPlan();
  };

  const styles = {
    bgColor: {
      backgroundColor: "#1360ef",
      color: "#fff",
    },
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="checkout-paymentBox">
          {success && <p className="text-align-center">{successMessage}</p>}

          {!success && (
            <>
              <div
                style={{ padding: "0.8rem" }}
                className="FormRow elements-style"
              >
                <CardElement
                  options={CARD_OPTIONS}
                  onChange={(e) => {
                    if (e.complete) {
                      setCardCompleted(true);
                    }

                    if (e.error) {
                      setPayment({ status: "error" });
                      setCardCompleted(false);
                    }
                  }}
                  hidePostalCode={true}
                />
              </div>
            </>
          )}
        </div>
        <div className="stripe-box">
          <div className="cards-box">
            <FontAwesomeIcon className="payment-text" icon={faCcVisa} />
            <FontAwesomeIcon className="payment-text" icon={faCcMastercard} />
            <FontAwesomeIcon className="payment-text" icon={faCcAmex} />
          </div>
          <a href="https://stripe.com/" rel="noreferrer" target="_blank">
            <img
              alt="Stripe"
              src="https://yoshi-projects.s3.eu-west-3.amazonaws.com/Datayoshi/Assets/stripe.svg"
              className="stripe-logo"
            />
          </a>
          <div className="lock-box">
            <LockIcon />
          </div>
        </div>

        {errorMessage && !success && (
          <div className="error__box">
            <p className="error__wrong">{errorMessage}</p>
          </div>
        )}
        {!success && (
          <>
            {paymentStarted ? (
              <div className="flex-center margin-top-1">
                <div className="lds-default">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            ) : (
              <div className="checkout-buttonBox">
                <button
                  disabled={
                    !["initial", "succeeded", "error"].includes(
                      payment.status
                    ) || !stripe
                  }
                  type="submit"
                  variant="contained"
                  disabled={email && cardCompleted ? false : true}
                  style={
                    email && cardCompleted
                      ? null
                      : { backgroundColor: "rgba(0, 0, 0, 0.12)" }
                  }
                  className="button-primary large full-width w-button"
                >
                  {errorMessage
                    ? `Retry now - $${currentPrice}`
                    : `${
                        selectedPlan.plan !== "lifetime" && trialDays > 0
                          ? "Start trial"
                          : "Pay"
                      }  - $${currentPrice}`}
                </button>
              </div>
            )}
          </>
        )}
      </form>

      {prod ? null : <PaymentStatus status={payment.status} />}
      {prod ? null : <PrintObject content={payment} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.authentication,
});

export default connect(mapStateToProps, { loginUser })(ElementsForm);
