import React, { useEffect, useState } from "react";
import isEmpty from "../../validation/is-empty";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import countries from "../../utils/listCountries";
import skills from "../../utils/listSkills";
import frequencies from "../../utils/listFrequencies";
import jobs from "../../utils/listJobs";
import ConfirmCancellation from "./confirmCancellation";
import PaymentCandidate from "../Payment/paymentCandidate";
import ConfirmUnsubscribeActivate from "./confirmUnsubscribeActivate";
import {
  getMyNewsletter,
  updateMyNewsletter,
  getCurrentPrices,
} from "../../utils/api";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Button } from "@material-ui/core";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import seniorityLevels from "../../utils/listSeniorityLevels";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiAutocomplete-input": {
      borderColor: "transparent",
      border: "transparent !important",
      boxShadow: "none",
      fontSize: "15px",
      width: "initial !important",
    },
    "& .MuiAutocomplete-input:focus": {
      backgroundColor: "transparent !important",
    },
    "& .MuiInputBase-root": {
      background: " 0 0",
      color: "#232226",
      backgroundColor: "#f0f5f7",
      border: "1px solid #e2e4e9",
      width: "100%",
      padding: "10px 20px",
      minHeight: "60px",
      lineHeight: "1.3",
      fontWeight: "400",
      fontSize: "4px",
      borderRadius: "8px",
      position: "relative",
      boxShadow: "0 7px 9px rgb(29 14 98 / 5%)",
      transition: "all cubic-bezier(0.4, 0, 0.2, 1) 0.4s",
      outline: "0",
    },
    "& .MuiSvgIcon-root": {
      color: "#773030",
      marginRight: "10px",
      width: "1.2em",
      height: "1.2em",
      /*content: "\25BE",*/
    },

    "& .MuiChip-root": {
      color: "rgba(0, 0, 0, 0.87)",
      border: "none",
      cursor: "default",
      /* height: 32px, */
      display: "inline-flex",
      outline: "0",
      padding: "0",
      fontSize: "0.8125rem",
      boxSizing: "border-box",
      transition:
        "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      alignItems: "center",
      fontFamily: "Roboto",
      whiteSpace: "nowrap",
      borderRadius: "16px",
      verticalAlign: "middle",
      justifyContent: "center",
      textDecoration: "none",
      backgroundColor: "white",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#fff8", // Semi-transparent underline
    },
  },
}));

const Start = ({ handleFooter, ...props }) => {
  const history = useHistory();
  const classes = useStyles();
  const [skillsAdded, setSkillsAdded] = useState(["SQL"]);
  const [seniorityLevelsAdded, setSeniorityLevelsAdded] = useState([]);
  const [jobsAdded, setJobsAdded] = useState([]);
  const [countrySelected, setCountrySelected] = useState("United States");
  const [frequency, setFrequency] = useState("freq-1");
  const [frequencyName, setFrequencyName] = useState("Once a week (Mon)");
  const [planType, setPlanType] = useState("");
  const [planName, setPlanName] = useState("");
  const [proposition, setProposition] = useState("jobs");
  const [paymentPage, setPaymentPage] = useState(false);
  const [trialEndDate, setTrialEndDate] = useState(null);
  const [showDialogCancellation, setShowDialogCancellation] = useState(false);
  const [cancellationMessage, setCancellationMessage] = useState(null);
  const [isAccountActive, setIsAccountActive] = useState(true);
  const [isSubscribedNewsletter, setIsSubscribedNewsletter] = useState(false);
  const [newsletterId, setNewsletterId] = useState(null);

  const [isPremium, setIsPremium] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [trialDays, setTrialDays] = useState(null);

  const [pricesStripe, setPricesStripe] = useState([]);
  const [planNameSelected, setPlanNameSelected] = useState("Monthly");
  const [planStripeSelected, setPlanStripeSelected] = useState({});
  const [plansStripe, setPlansStripe] = useState([]);

  const setDialogDeleteToFalse = () => {
    setShowDialogCancellation(false);
  };

  const setDialogDeleteToTrue = () => {
    setShowDialogCancellation(true);
  };

  const today = new Date(Date.now());

  const handleChangePlan = (event) => {
    const planName = event.target.name;
    if (event.target.checked) {
      setPlanNameSelected(event.target.value);
      setPlanStripeSelected(
        pricesStripe.filter(
          (x) =>
            x.plan === planName.toLowerCase() && x.proposition === proposition
        )[0]
      );
    }
  };

  const handleChangeFrequency = (event) => {
    const frequencyName = frequencies.filter(
      (x) => x.frequencyValue === event.target.value
    )[0].frequencyLabel;

    setFrequency(event.target.value);
    setFrequencyName(frequencyName);
  };

  const handleChangeJobs = (event) => {
    if (event.target.checked) {
      setJobsAdded([...jobsAdded, event.target.name]);
    } else {
      let filteredArray = jobsAdded.filter(
        (item) => item !== event.target.name
      );
      setJobsAdded(filteredArray);
    }
  };
  const handleChangeSkills = (event) => {
    if (event.target.checked) {
      setSkillsAdded([...skillsAdded, event.target.name]);
    } else {
      let filteredArray = skillsAdded.filter(
        (item) => item !== event.target.name
      );
      setSkillsAdded(filteredArray);
    }
  };

  const handleChangeProposition = (event) => {
    if (event.target.checked) {
      const newPrices = pricesStripe.filter(
        (x) => x.proposition === event.target.name
      );

      setProposition(event.target.name);
      setPlansStripe(newPrices);
      setPlanStripeSelected(
        pricesStripe.filter(
          (x) => x.plan === "monthly" && x.proposition === "bundle"
        )[0]
      );
    } else {
      const newPrices = pricesStripe.filter((x) => x.proposition === "jobs");
      setPlansStripe(newPrices);
      setProposition("jobs");
      setPlanNameSelected("Monthly");
      setPlanStripeSelected(
        pricesStripe.filter(
          (x) => x.plan === "monthly" && x.proposition === "jobs"
        )[0]
      );
    }
  };

  const handlePaymentPage = () => {
    setPaymentPage(!paymentPage);
    window.scrollTo(0, 0);
  };

  const handleCancellationMessage = (message) => {
    setCancellationMessage(message);
    setIsSubscribedNewsletter(!isSubscribedNewsletter);
  };

  const selectCountry = (country) => {
    if (country === "Select a country") {
      setCountrySelected(null);
    } else {
      setCountrySelected(country);
    }
  };

  const onSubmit = (e) => {
    setIsLoading(true);

    if (isPremium) {
      e.preventDefault();

      const userData = {
        country: countrySelected,
        frequency: frequency,
        frequencyName: frequencyName,
        skills: skillsAdded,
        jobs: jobsAdded,
        seniorityLevels: seniorityLevelsAdded,
      };

      updateMyNewsletter(userData).then((res) => {
        if (res.success) {
          window.location.reload();
        }
      });
    } else {
      history.push("/upgrade");
    }
  };

  useEffect(() => {
    handleFooter();
    const { auth } = props;
    setIsLoading(true);
    const isAuthenticated = auth.isAuthenticated;

    if (isAuthenticated) {
      getMyNewsletter().then((res) => {
        console.log(res.data);
        if (
          !isEmpty(res.data) &&
          res.data.isAccountActive &&
          res.data.newsletterInfo.formula === "premium"
        ) {
          const otherInfo = res.data;
          const newsletterInfo = otherInfo.newsletterInfo;
          console.log(otherInfo);

          if (newsletterInfo.planName) setPlanName(newsletterInfo.planName);
          if (newsletterInfo.planType) setPlanType(newsletterInfo.planType);
          if (newsletterInfo.skills) setSkillsAdded(newsletterInfo.skills);
          if (newsletterInfo.jobs) setJobsAdded(newsletterInfo.jobs);
          if (newsletterInfo.seniorityLevels)
            setSeniorityLevelsAdded(newsletterInfo.seniorityLevels);
          if (newsletterInfo.country)
            setCountrySelected(newsletterInfo.country);
          else {
            setCountrySelected("United States");
          }
          if (newsletterInfo.frequency) setFrequency(newsletterInfo.frequency);
          if (newsletterInfo.frequencyName)
            setFrequencyName(newsletterInfo.frequencyName);
          if (newsletterInfo.trialEndDate)
            setTrialEndDate(newsletterInfo.trialEndDate);
          if (newsletterInfo.isSubscribedNewsletter)
            setIsSubscribedNewsletter(newsletterInfo.isSubscribedNewsletter);
          if (newsletterInfo.planDetails)
            setProposition(newsletterInfo.planDetails.proposition);
          if (newsletterInfo.withInterviewPrep)
            setProposition(newsletterInfo.withInterviewPrep);
          if (newsletterInfo._id) setNewsletterId(newsletterInfo._id);
          setIsAccountActive(otherInfo.isAccountActive);
          setIsLoading(false);
          if (newsletterInfo.formula === "premium") setIsPremium(true);
        }

        getCurrentPrices().then((res) => {
          const prices = res.prices;
          const planStripe = res.prices.filter((x) => x.proposition === "jobs");
          const initialPlan = res.prices.filter(
            (x) => x.proposition === "jobs" && x.plan === "monthly"
          )[0];

          setPricesStripe(prices);
          setPlansStripe(planStripe);
          setPlanStripeSelected(initialPlan);

          setTrialDays(res.trialDays);
        });
        setIsLoading(false);
      });
    } else {
      history.push("/login");
    }
  }, []);

  console.log(trialDays);

  return (
    <>
      {paymentPage ? (
        <PaymentCandidate
          selectedPlan={planStripeSelected}
          trialDays={trialDays}
          newsletterData={{
            skills: skillsAdded,
            country: countrySelected,
            jobs: jobsAdded,
            plan: proposition,
            frequency: frequency,
            frequencyName: frequencyName,
            seniorityLevels: seniorityLevelsAdded,
          }}
        />
      ) : (
        <>
          {isLoading ? (
            <div className="flex-center">
              <div className="reverse-spinner"></div>
            </div>
          ) : (
            <div className="section short wf-section">
              <div className="container-medium">
                <div className="w-layout-grid  justify-center">
                  <div
                    id="w-node-bb2c87d9-0030-2b78-7e8f-85f48d248aaf-0a0cf2d8"
                    data-w-id="bb2c87d9-0030-2b78-7e8f-85f48d248aaf"
                    style={{ opacity: 1 }}
                    className="form-container"
                  >
                    <div className="container">
                      {showDialogCancellation &&
                        planType === "subscription" && (
                          <ConfirmCancellation
                            setDialogUploadToFalse={setDialogDeleteToFalse}
                            handleCancellationMessage={
                              handleCancellationMessage
                            }
                            typeCancellation={
                              trialEndDate &&
                              !(today > new Date(Date.parse(trialEndDate))) &&
                              planType === "subscription"
                                ? "trial"
                                : "subscription"
                            }
                          />
                        )}

                      {showDialogCancellation &&
                        planType !== "subscription" && (
                          <ConfirmUnsubscribeActivate
                            setDialogUploadToFalse={setDialogDeleteToFalse}
                            handleCancellationMessage={
                              handleCancellationMessage
                            }
                            isSubscribedNewsletter={isSubscribedNewsletter}
                            newsletterId={newsletterId}
                          />
                        )}

                      <h1 className="text-align-center margin-top-5 ">
                        {isPremium ? (
                          <>
                            Manage your{" "}
                            <span className="cta-highlighted-color">
                              newsletter
                            </span>{" "}
                            subscription
                          </>
                        ) : (
                          <>
                            Save your{" "}
                            <span className="cta-highlighted-color">
                              job preferences
                            </span>
                          </>
                        )}
                      </h1>
                    </div>

                    {/*!isPremium && (
            <>
              <RadioGroup aria-label='plan' name={'free'} value={'free'}>
                <FormControlLabel
                  value={'free'}
                  control={<Radio />}
                  name={'free'}
                  label={'Free plan'}
                  disabled
                />
              </RadioGroup>

              <Link to='/plans'>
                {' '}
                <div className='flex-center margin-top-1'>
                  <button
                    type='submit'
                    className='button-primary large w-button'
                  >
                    Get PRO newsletter
                  </button>
                </div>
              </Link>

              {cancellationMessage && isAccountActive && !isPremium && (
                <p className='margin-top-2'>{cancellationMessage}</p>
              )}
            </>
              )
              
               {!isAccountActive && (
                <>
                  <p>Your account is not active.</p>
                  <Link to='/plans'>
                    <div className='margin-top-1'>
                      <button className='button-primary large w-button'>
                        Buy new subscription
                      </button>
                    </div>
                  </Link>
                </>
              )}
              
              */}

                    {!cancellationMessage && isAccountActive && isPremium && (
                      <>
                        <h3 className="margin-top-2">Current plan</h3>
                        <div className="current-plan-box">
                          <RadioGroup
                            aria-label="plan"
                            name={planName}
                            value={planName}
                          >
                            <FormControlLabel
                              value={planName}
                              control={<Radio />}
                              name={planName}
                              label={planName}
                              disabled
                            />
                          </RadioGroup>
                          {planType === "subscription" ? (
                            <div
                              onClick={setDialogDeleteToTrue}
                              className="current-plan-button"
                            >
                              <Button variant="contained" color="secondary">
                                Cancel{" "}
                                {trialEndDate &&
                                !(today > new Date(Date.parse(trialEndDate)))
                                  ? " trial"
                                  : " subscription"}
                              </Button>
                            </div>
                          ) : (
                            <div
                              onClick={setDialogDeleteToTrue}
                              className="current-plan-button"
                            >
                              <Button
                                variant="contained"
                                color={
                                  isSubscribedNewsletter
                                    ? "secondary"
                                    : "primary"
                                }
                              >
                                {isSubscribedNewsletter
                                  ? "Pause "
                                  : "Activate "}{" "}
                                Newsletter
                              </Button>
                            </div>
                          )}
                        </div>
                        {trialEndDate &&
                          planType === "subscription" &&
                          !(today > new Date(Date.parse(trialEndDate))) && (
                            <div className="current-plan-button">
                              Your trial ends on{" "}
                              <span className="cta-highlighted-color">
                                {" "}
                                {moment(trialEndDate).format("MMM Do, YYYY")}
                              </span>
                            </div>
                          )}
                      </>
                    )}
                    {cancellationMessage && isAccountActive && isPremium && (
                      <p>{cancellationMessage}</p>
                    )}

                    <div className="form-block w-form">
                      <form
                        id="email-form-2"
                        name="email-form-2"
                        data-name="Email Form 2"
                        onSubmit={onSubmit}
                      >
                        <div className="w-layout-grid grid-form">
                          <div>
                            <h3>Country</h3>
                            <div>
                              <select
                                className={`input w-input`}
                                name="country"
                                value={countrySelected}
                                onChange={(e) => selectCountry(e.target.value)}
                                disabled={
                                  false //isPremium && isAccountActive ? false : true
                                }
                              >
                                {countries.map((country, i) => (
                                  <option key={i} value={country}>
                                    {country}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div>
                            <h3>Newsletter Frequency</h3>

                            <div>
                              <select
                                className={`input w-input`}
                                name="frequency"
                                value={frequency}
                                onChange={handleChangeFrequency}
                                disabled={
                                  false //isPremium && isAccountActive ? false : true
                                }
                              >
                                {frequencies.map((frequency, i) => (
                                  <option
                                    key={i}
                                    value={frequency.frequencyValue}
                                  >
                                    {frequency.frequencyLabel}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div>
                          <h3>Newsletter type</h3>

                          <div className="flex-wrap">
                            <div
                              className={`margin-right-2`}
                              onClick={
                                isPremium && isAccountActive
                                  ? null
                                  : handleChangeProposition
                              }
                            >
                              <label className="w-checkbox">
                                <div
                                  className={`w-checkbox-input w-checkbox-input--inputType-custom checkbox ${
                                    !isEmpty(proposition) &&
                                    "w--redirected-checked"
                                  } `}
                                />
                                <input
                                  type="checkbox"
                                  value={"jobs"}
                                  name={"jobs"}
                                  //checked={proposition === 'jobs' ? true : false}
                                  style={{
                                    opacity: 0,
                                    position: "absolute",
                                    zIndex: -1,
                                  }}
                                  disabled={
                                    isPremium && isAccountActive ? true : false
                                  }
                                />
                                <span
                                  htmlFor="Show Salary"
                                  className="check-label w-form-label"
                                >
                                  Jobs{" "}
                                </span>
                              </label>
                            </div>
                            <div
                              className={`margin-right-2`}
                              onClick={
                                isPremium && isAccountActive
                                  ? null
                                  : handleChangeProposition
                              }
                            >
                              <label className="w-checkbox">
                                <div
                                  className={`w-checkbox-input w-checkbox-input--inputType-custom checkbox ${
                                    proposition === "bundle" &&
                                    "w--redirected-checked"
                                  } `}
                                />
                                <input
                                  type="checkbox"
                                  value={"bundle"}
                                  name={"bundle"}
                                  //checked={proposition === 'jobs' ? true : false}
                                  style={{
                                    opacity: 0,
                                    position: "absolute",
                                    zIndex: -1,
                                  }}
                                  disabled={
                                    isPremium && isAccountActive ? true : false
                                  }
                                />
                                <span
                                  htmlFor="Show Salary"
                                  className="check-label w-form-label interview-questions"
                                >
                                  Interview Questions{" "}
                                  <span class="job-card-date margin-top-1">
                                    <div>
                                      Questions on Monday and Answers on Tuesday
                                    </div>
                                  </span>
                                </span>
                              </label>
                            </div>
                          </div>
                          {!isPremium && (
                            <>
                              {" "}
                              <h3>Select plan</h3>
                              <div className="">
                                {plansStripe &&
                                  plansStripe
                                    .sort(
                                      (a, b) =>
                                        a.details[0].unit_amount -
                                        b.details[0].unit_amount
                                    )
                                    .map((plan, i) => (
                                      <div
                                        onClick={
                                          isPremium && isAccountActive
                                            ? null
                                            : handleChangePlan
                                        }
                                        className="margin-top-1"
                                      >
                                        <label className="w-checkbox">
                                          <div
                                            className={`w-checkbox-input w-checkbox-input--inputType-custom checkbox ${
                                              planNameSelected ===
                                                plan.plan
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                  plan.plan.slice(1) &&
                                              "w--redirected-checked"
                                            } `}
                                          />
                                          <input
                                            type="checkbox"
                                            value={
                                              plan.plan
                                                .charAt(0)
                                                .toUpperCase() +
                                              plan.plan.slice(1)
                                            }
                                            name={
                                              plan.plan
                                                .charAt(0)
                                                .toUpperCase() +
                                              plan.plan.slice(1)
                                            }
                                            //checked={proposition === 'jobs' ? true : false}
                                            style={{
                                              opacity: 0,
                                              position: "absolute",
                                              zIndex: -1,
                                            }}
                                            disabled={
                                              isPremium && isAccountActive
                                                ? true
                                                : false
                                            }
                                          />
                                          <div className="label-prices check-label w-form-label">
                                            {`${
                                              plan.plan
                                                .charAt(0)
                                                .toUpperCase() +
                                              plan.plan.slice(1)
                                            } - $${
                                              plan.details[0].unit_amount / 100
                                            }`}{" "}
                                            (
                                            <del>
                                              {(plan.details[0].unit_amount /
                                                100) %
                                                5 !==
                                              0
                                                ? ` $${
                                                    (plan.details[0]
                                                      .unit_amount /
                                                      100) *
                                                      2 +
                                                    1
                                                  }`
                                                : ` $${
                                                    (plan.details[0]
                                                      .unit_amount /
                                                      100) *
                                                    2
                                                  }`}
                                            </del>
                                            ){" "}
                                            {plan.plan === "lifetime" ? null : (
                                              <>
                                                {trialDays > 0 && (
                                                  <>
                                                    -{" "}
                                                    <span className="cta-highlighted-color">
                                                      {trialDays}-day free trial
                                                    </span>
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </label>
                                      </div>
                                    ))}
                              </div>
                            </>
                          )}

                          {!isEmpty(proposition) && (
                            <>
                              {" "}
                              <h3>Jobs</h3>
                              <div className="flex-wrap">
                                {jobs.sort().map((job, i) => (
                                  <div
                                    className={`margin-right-2`}
                                    onClick={handleChangeJobs}
                                    key={i}
                                  >
                                    <label className="w-checkbox">
                                      <div
                                        className={`w-checkbox-input w-checkbox-input--inputType-custom checkbox ${
                                          jobsAdded.includes(job) &&
                                          "w--redirected-checked"
                                        } `}
                                      />
                                      <input
                                        type="checkbox"
                                        value={job}
                                        name={job}
                                        checked={jobsAdded.includes(job)}
                                        style={{
                                          opacity: 0,
                                          position: "absolute",
                                          zIndex: -1,
                                        }}
                                        disabled={
                                          false //isPremium && isAccountActive ? false : true
                                        }
                                      />
                                      <span
                                        htmlFor="Show Salary"
                                        className="check-label w-form-label"
                                      >
                                        {job}
                                      </span>
                                    </label>
                                  </div>
                                ))}
                              </div>
                              <h3>Experience Level </h3>
                              <Autocomplete
                                multiple
                                id="size-medium-standard-multi"
                                size="medium"
                                options={seniorityLevels.sort()}
                                getOptionLabel={(option) => option}
                                value={seniorityLevelsAdded}
                                onChange={(event, newValue) => {
                                  setSeniorityLevelsAdded(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Add experience level"
                                    className={classes.root}
                                  />
                                )}
                              />
                              <h3>Skills</h3>
                              <Autocomplete
                                multiple
                                id="size-medium-standard-multi"
                                size="medium"
                                options={skills.sort()}
                                getOptionLabel={(option) => option}
                                value={skillsAdded}
                                onChange={(event, newValue) => {
                                  setSkillsAdded(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Add skills"
                                    className={classes.root}
                                  />
                                )}
                              />
                              {/* 
                              
                               <div className="flex-wrap ">
                                {skills.sort().map((skill, i) => (
                                  <div
                                    className={`margin-right-2`}
                                    onClick={handleChangeSkills}
                                    key={i}
                                  >
                                    <label className="w-checkbox">
                                      <div
                                        className={`w-checkbox-input w-checkbox-input--inputType-custom checkbox ${
                                          skillsAdded.includes(skill) &&
                                          "w--redirected-checked"
                                        } `}
                                      />
                                      <input
                                        type="checkbox"
                                        value={skill}
                                        name={skill}
                                        checked={skillsAdded.includes(skill)}
                                        style={{
                                          opacity: 0,
                                          position: "absolute",
                                          zIndex: -1,
                                        }}
                                        disabled={
                                          false //isPremium && isAccountActive ? false : true
                                        }
                                      />
                                      <span
                                        htmlFor="Show Salary"
                                        className="check-label w-form-label"
                                      >
                                        {skill}
                                      </span>
                                    </label>
                                  </div>
                                ))}
                              </div>
                              
                              */}
                            </>
                          )}
                        </div>

                        {isSubscribedNewsletter &&
                          isAccountActive &&
                          isPremium && (
                            <div className="flex-center margin-top-2">
                              <button
                                type="submit"
                                className="button-primary large w-button"
                              >
                                Update newsletter
                              </button>
                            </div>
                          )}

                        {(!isPremium || (isPremium && !isAccountActive)) && (
                          <div className="flex-center margin-top-2">
                            <button
                              type="submit"
                              className="button-primary large w-button"
                              onClick={handlePaymentPage}
                            >
                              Activate newsletter
                            </button>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.authentication,
});

export default connect(mapStateToProps, {})(Start);
