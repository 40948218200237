import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Import Link from React Router for navigation
import { getAllChallengesThisWeek } from "../../utils/api";
import { capitalizeFirstLetter } from "../../utils/helpers";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

function CodingChallenges({ ...props }) {
  const history = useHistory();
  const [challenges, setChallenges] = useState([]);

  useEffect(() => {
    const { auth } = props;

    const isAuthenticated = auth.isAuthenticated;

    if (isAuthenticated) {
      getAllChallengesThisWeek().then((res) => {
        if (res.status === "success") {
          setChallenges(res.data.challenges);
        } else {
          console.error("Error fetching challenges:", res.message);
        }
      });
    } else {
      history.push("/login");
    }
  }, []);

  return (
    <div>
      <h1 className="text-center margin-top-1">
        Ace your interviews with confidence.
      </h1>

      <div className="challenge-container">
        {" "}
        <p className="text-center">
          Train with our weekly interview questions to land your dream job.
        </p>
        <div className="challenge-list">
          {challenges.map((challenge) => (
            <div key={challenge._id} className="challenge-item">
              <Link to={`/coding-challenge/${challenge.slug}`}>
                {" "}
                {/* Link wrapping the title */}
                <h2 className="challenge-title">{challenge.problemName}</h2>
              </Link>
              <p className="challenge-question">{challenge.problemQuestion}</p>
              <div className="challenge-tags">
                <span className="challenge-tag challenge-difficulty">
                  {capitalizeFirstLetter(challenge.difficulty)}
                </span>
                <span className="challenge-tag challenge-language">
                  {challenge.programmingLanguage}
                </span>
                <span className="challenge-tag challenge-language">
                  {challenge.job}
                </span>
                <span className="challenge-tag challenge-language">
                  {challenge.industry}
                </span>
                {/* Add more tags here if needed */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.authentication,
});

export default connect(mapStateToProps, {})(CodingChallenges);
